<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item v-if="userInfo.rolename!='项目调度员'&&userInfo.rolename!='项目管理员'" label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="查询接单时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="完成标志" prop="eventstatus" style="width: 25%">
              <a-select v-model="queryParams.recordstatus">
                <a-select-option :value="1">处理中</a-select-option>
                <a-select-option :value="2">待验证</a-select-option>
                <a-select-option :value="3">已完成</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="width: 20%;float:right;">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="品质改进单号" prop="improveordernum" style="width: 25%">
              <a-input v-model="queryParams.improveordernum"></a-input>
            </a-form-model-item>
            <a-form-model-item label="查阅人" prop="checker" style="width: 25%">
              <a-input v-model="queryParams.checker"></a-input>
            </a-form-model-item>
            <a-form-model-item label="是否查阅" prop="ischecked" style="width: 25%">
              <a-select v-model="queryParams.ischecked">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :value="0">未查阅</a-select-option>
                <a-select-option :value="1">已查阅</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="monitorpointname" slot-scope="value, record">
            <span v-if="!record.checker||!record.checker.includes(userInfo.username)" class="table-dot" style="background-color: #ff4d4f;"></span>
            <a-tooltip :title="value">
              <span>{{value}}</span>
            </a-tooltip>
          </span>
          <span slot="orderstime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="status" slot-scope="value">
             <a-tag :color="value==3?'green':value==2?'yellow':'red'">{{value?processTypes[value-1].value:''}}</a-tag>
          </span>
          <span slot="issendorder" slot-scope="value">
            <span>{{value==1?'是':'否'}}</span>
          </span>
          <span slot="checkrecord" slot-scope="value,record">
            <a-button type="primary" size="small" @click="addCheckBackData(value, record)">已阅</a-button>
          </span>
          <span slot="checkrecords" slot-scope="value,record">
            <a-button type="link" size="small" @click="checkRecords(value, record)">查看</a-button>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item v-if="userInfo.usertype==0||userInfo.usertype==1||userInfo.rolename=='项目督导员'" :key="'detail-'+record.improveorderid">详情</a-menu-item>
                <a-menu-item v-if="userInfo.usertype==0||userInfo.rolename=='项目管理员'||userInfo.rolename=='项目其他管理员'||userInfo.rolename=='项目工程管理员'" :key="'edit-'+record.improveorderid">跟进</a-menu-item>
                <a-menu-item :key="'export-'+record.improveorderid">导出</a-menu-item>
                <a-menu-item v-if="userInfo.usertype==0||userInfo.rolename=='项目督导员'||userInfo.rolename=='项目管理员' ||userInfo.rolename=='项目其他管理员'||userInfo.rolename=='项目工程管理员'" :key="'delete-'+record.improveorderid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal :visible="checkRecordsVisible" title="查阅记录" @cancel="checkRecordsVisible=false" :footer="null" width="800px">
      <a-table :columns="columns" :rowKey="(record,index)=> index" :data-source="checkData" style="width: 750px">
        <span slot="checktime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
      </a-table>
    </a-modal>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import pagination from '@/mixins/pagination'
import {getItemFromArrayByKey, getTimeRange} from 'U'
import {mapState} from "vuex";
import deptselect from "@/mixins/deptselect";
import {
  deleteImproveOrder,
  exportFireAlarmRecord,
  exportImproveOrderWord,
  getImproveOrderListByCondition
} from "A/patrol";
import moment from "moment";
import addOrEditModal from "V/supervise/improveOrder/addOrEditModal";
import {processTypes} from "@/json/wlwhistory";
import {addCheckBack, getCheckBackListByCondition} from "A/wlw";
export default {
  name:'improveOrder',
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      showAdvanced: false,
      checkRecordsVisible:false,
      userdepidCascaderSelected: [],
      queryInstallDate:null,
      processTypes,
      checkData:[],
      queryParams: {
        monitorpointname:'',
        improveordernum:'',
        orderstime:'',
        recordstatus:'',
        checker:'',
        ischecked:'',
      },
      columns:[
        {
          title: '查阅者姓名',
          dataIndex: 'checkername',
          key: 'checkername',

          ellipsis: true,
        },
        {
          title: '联系电话',
          dataIndex: 'checkerphone',
          key: 'checkerphone',

          ellipsis: true,
        },
        {
          title: '查阅时间',
          dataIndex: 'checktime',
          key: 'checktime',

          ellipsis: true,
          scopedSlots: { customRender: 'checktime' },
        },
      ],
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          scopedSlots: { customRender: 'monitorpointname' },
        },
        {
          title: '品质改进单编号',
          dataIndex: 'improveordernum',
          key: 'improveordernum',
          ellipsis: true,
        },
        {
          title: '接单时间',
          dataIndex: 'orderstime',
          key: 'orderstime',
          ellipsis: true,
          scopedSlots: { customRender: 'orderstime' },
        },
        {
          title: '发单人',
          dataIndex: 'recordman',
          key: 'recordman',
          ellipsis: true,
        },
        {
          title: '完成标志',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          width: 80,
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '查阅人',
          dataIndex: 'checker',
          key: 'checker',
          ellipsis: true,
          align:'center',
          width: 150,
          scopedSlots: { customRender: 'checker' },
        },
        {
          title: '查阅',
          dataIndex: 'checkrecord',
          key: 'checkrecord',
          ellipsis: true,
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'checkrecord' },
        },
        {
          title: '查阅记录',
          dataIndex: 'checkrecords',
          key: 'checkrecords',
          ellipsis: true,
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'checkrecords' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      isclickrow:false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '品质改进单';
    },
    breadcrumb() {
      return [
        {
          name: '督导管理',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.getTableData();
    this.initDeptOptionsAll();
  },
  methods: {
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            if(!this.isclickrow) {
              this.modalShowType = "detail";
              this.modalDetailData = record;
              this.modalDetailData.improveorderid=record.improveorderid
              this.modalDetailData.orderstime=this.modalDetailData.orderstime?moment(this.modalDetailData.orderstime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
              this.modalDetailData.analysistime=this.modalDetailData.analysistime?moment(this.modalDetailData.analysistime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
              this.modalDetailData.improvetime=this.modalDetailData.improvetime?moment(this.modalDetailData.improvetime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
              this.modalDetailData.scheduledtime=this.modalDetailData.scheduledtime?moment(this.modalDetailData.scheduledtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
              this.modalDetailData.verificationtime=this.modalDetailData.verificationtime?moment(this.modalDetailData.verificationtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
              this.modalVisible = true;
            }
          },
        },
      }
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
      this.queryParams.recordstatus="";
      this.userdepidCascaderSelected=[];
      this.queryInstallDate = [];
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        checkername:this.userInfo.username,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getImproveOrderListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.improveorderid);
      }).catch(()=>{
      });
    },
    delete(improveorderid) {
      this.showLoading();
      if(improveorderid) {
        let params = {
          improveorderid
        };
        deleteImproveOrder(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
        this.modalDetailData.improveorderid=value
        this.modalDetailData.orderstime=this.modalDetailData.orderstime?moment(this.modalDetailData.orderstime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
        this.modalDetailData.analysistime=this.modalDetailData.analysistime?moment(this.modalDetailData.analysistime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
        this.modalDetailData.improvetime=this.modalDetailData.improvetime?moment(this.modalDetailData.improvetime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
        this.modalDetailData.scheduledtime=this.modalDetailData.scheduledtime?moment(this.modalDetailData.scheduledtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
        this.modalDetailData.verificationtime=this.modalDetailData.verificationtime?moment(this.modalDetailData.verificationtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss"):null
      }
      this.modalVisible = true;
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let improveorderid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'improveorderid', improveorderid);
      if(type == 'delete') {
        this.deleteConfirm(improveorderid, record)
      }else if(type == 'edit'){
        this.showModal(type, improveorderid, record)
      }else if(type=='export'){
        this.exportImproveOrder(record)
      } else{
        this.showModal(type, improveorderid, record)
      }
    },
    exportImproveOrder(record){
      let params={
        ...record
      }
      exportImproveOrderWord(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("操作成功")
        }
      })
    },
    checkRecords(value, record){
      this.isclickrow=true
      this.checkRecordsVisible=true
      this.getCheckBackDataList(record.improveorderid)
    },
    addCheckBackData(value,record){
      this.isclickrow=true
      let params={
        eventrecordid:record.improveorderid,
        checkeraccount:this.userInfo.useraccount,
        checkername:this.userInfo.username,
        checkerphone:this.userInfo.phonenum,
        usertype:this.userInfo.usertype==0?this.usertype:this.userInfo.usertype,
        checktype:1,
        managetype:this.managetype,
        checktime:moment(new Date()).format("YYYYMMDDHHmmss")
      }
      addCheckBack(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("操作成功")
          this.isclickrow=false
          this.getTableData()
        }
      })
    },
    getCheckBackDataList(improveorderid){
      getCheckBackListByCondition({eventrecordid: improveorderid,checktype:1}).then(res=>{
        if(res&&res.returncode=='0'){
          this.checkData=res.item
        }
      })
    },
  }
}
</script>